import { Container } from './container';
import { Customer } from './customer';
import { Product, ProductVariant } from '@/interfaces/resources/product';
import { PaymentRefundOrderItemStateEnum } from '@/enums/PaymentRefundOrderItemStateEnum';
import { Invoice } from '@/interfaces/resources/invoice';

export interface Order {
  id: number | string;
  identifier: string;
  customer: Customer | null;
  address: any; // TODO
  items: OrderItem[];
  leave_at_door: boolean;
  leave_at_neighbor: boolean;
  state: OrderState;
  price: string;
  currency: string;
  total_price: string;
  delivery_date_from: string;
  delivery_date_to: string;
  delivery_cost: string;
  invoices: Invoice[] | null;
  payment_method_type: string | null;
  payment_intent: any; // TODO
  container: Container | null;
  returned_order_items: ReturnedOrderItem[];
  created_at: string;
}

export interface OrderItem {
  currency: string;
  id: number;
  original_price: string;
  price: string;
  product: Product;
  product_variant: ProductVariant;
  quantity: number;
  replaced_order_items: ReplacedOrderItem[];
  missing_quantity: number;
  missing_refund_amount: number;
  missing_refund_state: PaymentRefundOrderItemStateEnum | null;
}

export interface ReplacedOrderItem {
  id: number;
  original_price: string;
  price: string;
  product: Product;
  product_variant: ProductVariant;
  quantity: number;
}

export enum OrderState {
  NEW = 'new',
  PENDING_PAYMENT = 'pending_payment',
  PAYMENT_FAILED = 'payment_failed',
  PAYMENT_SUCCESS = 'payment_success',
  IN_REALIZATION = 'in_realization',
  IN_COMPLETION = 'in_completion',
  COMPLETED = 'completed',
  IN_VERIFICATION = 'in_verification',
  VERIFIED = 'verified',
  IN_DELIVERY = 'in_delivery',
  RECEIVED = 'received',
  CANCELED = 'canceled',
  REFUSED_BY_RECIPIENT = 'refused_by_recipient',
  PARTLY_REJECTED = 'partly_rejected',
  REJECTED = 'rejected',
  RETURNED = 'returned',
  REFUNDED = 'refunded',
}

export interface ReturnedOrderItem {
  id: number;
  order_item_id: number;
  order_item: OrderItem;
  product: Product;
  price: string;
  product_variant: ProductVariant;
  returned_quantity: number;
  refunded_price: string;
  state: PaymentRefundOrderItemStateEnum;
}

export interface MissingOrderItem {
  order_id: number;
  state: OrderState;
}
