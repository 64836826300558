import axios from 'axios';
import { InvoiceStoreData } from '@/interfaces/resources/invoice';

class InvoiceService {
  public async attachMedia(invoiceId: number, mediaIds: Array<number>) {
    try {
      const response = await axios.post(`invoice/${invoiceId}attach-media`, {
        media_ids: mediaIds,
      });

      return true;
    } catch (error) {
      return false;
    }
  }

  public async create(orderId: number, data: InvoiceStoreData) {
    try {
      const response = await axios.post(`/panel/api/v1/orders/${orderId}/invoices`, data);

      return response.data;
    } catch (error) {
      return null;
    }
  }

  public async update(orderId: number, invoiceId: number, data: InvoiceStoreData) {
    try {
      const response = await axios.put(`/panel/api/v1/orders/${orderId}/invoices/${invoiceId}`, data);

      return response.data;
    } catch (error) {
      return null;
    }
  }

  public async delete(orderId: number, invoiceId: number) {
      try {
      const response = await axios.delete(`/panel/api/v1/orders/${orderId}/invoices/${invoiceId}`);

      return true;
      } catch (error) {
      return false;
      }
  }
}

export default new InvoiceService();
